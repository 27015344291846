* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

:root {
  --ion-color-rose: #831843;
  --ion-color-rose-rgb: 131, 24, 67;
  --ion-color-rose-contrast: #ffffff;
  --ion-color-rose-contrast-rgb: 255, 255, 255;
  --ion-color-rose-shade: #73153b;
  --ion-color-rose-tint: #8f2f56;

  --ion-text-color: #881337;
  --ion-text-color-rgb: 136, 19, 55;

  --ion-color-step-50: #f9e6e9;
  --ion-color-step-100: #f3dbdf;
  --ion-color-step-150: #edd0d6;
  --ion-color-step-200: #e7c5cd;
  --ion-color-step-250: #e1bac3;
  --ion-color-step-300: #dbaeba;
  --ion-color-step-350: #d5a3b1;
  --ion-color-step-400: #cf98a7;
  --ion-color-step-450: #c98d9e;
  --ion-color-step-500: #c48295;
  --ion-color-step-550: #be778b;
  --ion-color-step-600: #b86c82;
  --ion-color-step-650: #b26178;
  --ion-color-step-700: #ac566f;
  --ion-color-step-750: #a64b66;
  --ion-color-step-800: #a03f5c;
  --ion-color-step-850: #9a3453;
  --ion-color-step-900: #94294a;
  --ion-color-step-950: #8e1e40;
}

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
}

ion-datetime {
  --background: #fff1f2;
  --background-rgb: 255, 241, 242;

  box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;
}

*::-webkit-media-controls-panel {

  display: none!important;

  -webkit-appearance: none;

}

*::--webkit-media-controls-play-button {

  display: none!important;

  -webkit-appearance: none;

}

*::-webkit-media-controls-start-playback-button {

  display: none!important;

  -webkit-appearance: none;

}
