.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background: #fff;
    border-radius: 5px;
    color: black;
    text-align: right;
    display: flex;
    align-items: center;
    font-size: 12px;
    max-width: 380px;
    max-height: 160px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
